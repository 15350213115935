import React, { useRef, useState, useEffect, useCallback } from "react";
import Layout from "@components/layout";
import { GatsbyImage } from "gatsby-plugin-image"

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import Animation modules
import { motion } from "framer-motion"

// Import SVG
import hatena_icon from '@images/hatena_icon.svg'; // ？マークの代わりのダミー
import yajieushi_icon from '@images/yajirushi-wh.svg'; // 
import yajirushi_icon02 from '@images/2step_yajirushi-sp.svg'; // 
import reg_iimg1 from '@images/regi_img01.webp';
import reg_iimg2 from '@images/regi_img02.webp';
import reg_iimg3 from '@images/regi_img03.webp';
import reg_yajirushi from '@images/2step_yajirushi.svg';

import topphone from '@images/phone-image.webp';
import topimage01 from '@images/top-image01_2.webp';

import how_img01 from '@images/how_img01.webp'; // 
import how_img02 from '@images/how_img02.svg'; // 
import how_img03 from '@images/how_img03.webp'; // 
import how_img04 from '@images/how_img04.webp'; // 
import how_img05 from '@images/how_img05.webp'; // 
import how_img06 from '@images/how_img06.webp'; // 
import how_img07 from '@images/how_img07.webp'; // 
import how_img08 from '@images/how_img08.webp'; // 
import how_img09 from '@images/how_img09.webp'; // 





// 関数呼び出し
import { FixedFoot } from "@components/footer.js";
import { SeoContents } from "@components/seo-data.js" // Seo情報設定


const PropHowto = () => {
  let fixedItem = FixedFoot( 'nomal' ); // 固定フッター

const entryUrl = '/register/';
 
  // Seo情報設定
  const pathName = 'howto';
  const seoTag = SeoContents({ Path:pathName });

  let setArray;
  let pushArray = [];
  const pickupPath = "/";
  const LinkGuidance = "/guidance#how";
  const LinkGuidance2 = "/guidance/#cost";
  const LinkHowto = "/howto#how";
  const LinkPayment = "/payment#pay";
  
  

  return (
  <>
    <Layout>
      {seoTag}
<div className="bg-[#F6F6F6] md:pb-[70px] pb-2">
        <div className="px-5 max-w-xl mx-auto md:max-w-5xl relative md:h-[330px] xl:overflow-visible overflow-hidden">
           <div className="pb-10 pt-0 md:pt-10  max-w-[800px] md:absolute md:left-5 md:top-[0%]">
              <h2 className="md:text-5xl text-3xl font-black mb-10 text-left pt-5">サービスのご案内</h2>
              <h3 className="md:text-3xl text-2xl font-black mb-5 text-left  text-[#1F55A6]">Just tradeは日本発のプロップファームとして<br></br>独自の審査基準を設けております。</h3>
            <p className="md:text-base text-base  font-black md:max-w-[500px] xl:max-w-[600px]">また、トレーダーの皆様にリスクなしで取引していただけるだけでなく<br></br>
               出金までの手続き状況をすべてご覧いただけるようなプラットフォームを構築しております。
            </p>
          </div>
          <div className="max-w-[650px] md:absolute md:right-[-23%] md:top-10">
             <motion.div className=" mx-auto text-center md:block md:mt-10 xl:mt-[0px] md:ml-[200px] xl:ml-[40px]" initial={{ opacity:0 ,x:100 }} whileInView={{ opacity:1 ,x:0 }} transition={{ duration: 1 }} viewport={{ once: true }}>
              <img className="mb-5 mx-auto h-auto" alt="" src={topimage01}/>
              </motion.div>
          </div>
        </div>

</div>
              {/* サブメニュー */}
        <div className="bg-[#F6F6F6] ">
                <a id="how" className="jump_point"></a>
              <div className="max-w-5xl mx-auto flex px-5">
                 <a href={LinkGuidance} className="md:text-base text-xs text-center md:py-4 py-2 px-2 md:px-10 w-1/3 bg-white mr-3 font-semibold border-b-[15px] border-[#F6F6F6] hover:text-[#1F55A6] text-[#999999] hover:border-white ">サービスの<br className="res_br"></br>内容</a>
                 <a href={LinkHowto}  className="md:text-base text-xs text-center py-4 md:py-4 px-2 md:px-10 w-1/3 bg-white mr-3 font-semibold border-b-[15px] border-white text-[#1F55A6] ">使い方</a>
                 <a href={LinkPayment}  className="md:text-base text-xs text-center  md:py-4 py-4  px-2 md:px-10 w-1/3 bg-white ml-3 font-semibold border-b-[15px] border-[#F6F6F6] hover:text-[#1F55A6] text-[#999999] hover:border-white ">出金について</a>
              </div>
        </div>
       






      <div className="px-5 max-w-xl mx-auto md:max-w-5xl  mt-[70px] ">
                {/* チャレンジ期間・表 */}
           <h3 className="md:text-3xl text-2xl font-black mb-5 text-left  text-[#1F55A6]">Just tradeの使い方</h3>
           <p className="mb-[30px]">
              Just tradeではアカウント登録からチャレンジまで最短5分で申請いただけます。<br></br>
              本人確認手続きは認定トレーダーに昇格後、トレードを開始するまで不要です。
            </p>
          <div className="mb-[70px]">
            <a className="duration-300 rounded-full block md:mx-0 mx-auto  text-center  text-white font-black pt-[10px] pb-[9px] px-5 bg-[#EFAA00]   text-[10px] max-w-[210px] mb-3 hover:tracking-widest">新規登録はこちら</a> 
            <a href={LinkGuidance2} className="duration-300 rounded-full block md:mx-0 mx-auto  text-center  text-white font-black pt-[10px] pb-[9px] px-5 bg-[#1F55A6]  text-[10px] max-w-[210px] hover:tracking-widest">チャレンジ費用についてはこちら</a> 
         </div>

           <h5 className="mb-10 text-2xl">新規登録ページよりアカウントを無料登録<br></br>マイページからチャレンジに申請</h5>
            <div className="flex md:mb-[30px] mb-[70px] items-center justify-between">
              <div className="max-w-[240px]">
                <p><img src={reg_iimg1} alt=""></img>
                </p>
                <p className="text-xs mt-2 min-h-[70px] text-center">アカウントを無料登録し<br></br>
                   マイページにログイン
                </p>
              </div>
              <p className="md:p-5 p-2"><img src={reg_yajirushi} alt=""></img>
              </p>
              <div className="max-w-[240px]">
              <p><img src={reg_iimg2} alt=""></img>
                </p>
                <p className="text-xs mt-2 min-h-[70px] text-center">マイページでチャレンジコースを選択後<br></br>
                  個人情報を登録
               </p>
              </div>
              <p className="md:p-5 p-2"><img src={reg_yajirushi} alt=""></img>
              </p>
              <div className="max-w-[240px]">
                <p ><img src={reg_iimg3} alt=""></img>
                </p>
                <p className="text-xs mt-2 min-h-[70px] text-center">チャレンジ開始！</p>
              </div>
            </div>
      </div>

      <div className="hidden px-5 xl:flex justify-center  max-w-5xl mx-auto mb-10">
            <a href={entryUrl} className="duration-300 rounded-full block xl:mx-0 mx-auto  max-w-xs text-center text-base text-white font-black py-5 px-16 my-2 bg-[#EFAA00] hover:tracking-widest ">新規登録はこちら</a> 
      </div>

      {/* チャレンジコース・表 */}
       <div className="bg-[#02478E] py-20">
      <div className="max-w-5xl mx-auto px-5">
         <h3 className="md:text-3xl text-2xl font-black mb-10 text-left  text-white">新規登録をしてチャレンジを始める</h3>



         <div className="p-10  bg-white mb-10  mx-auto w-full rounded-md md:flex justify-between">
            <div className="md:mr-5 md:mb-0 mb-5">
              <h4 className="text-[#1F55A6] text-xl mb-5 font-semibold">新規登録ページよりアカウントを登録する</h4>
              <p><a href={entryUrl} className="text-[#1F55A6] underline " >新規登録ページ</a>から無料で登録可能<br></br>
               お名前とメールアドレスを記入し、パスワードを決めて登録する
              </p>
            </div>
            <p className="max-w-[332px]"><img src={how_img01} alt=""></img></p>
         </div>
         <p className="flex justify-center mb-10"><img src={yajieushi_icon} alt=""></img></p>

         <div className="p-10  bg-white mb-10  mx-auto w-full rounded-md md:flex justify-between">
          <div className="md:mr-9 md:mb-0 mb-5">
            <h4 className="text-[#1F55A6] text-xl mb-5 font-semibold">自動返信メールを確認</h4>
            <p>新規登録ページで登録したメールアドレスに登録確認のメールが届きます<br></br>内容を確認し、マイページへのリンクからマイページへアクセスする。</p>
            </div>
            <p className="max-w-[332px] mx-auto"><img src={how_img02} alt="" className="mx-auto md:mx-0"></img></p>
         </div>
         <p className="flex justify-center mb-10"><img src={yajieushi_icon} alt=""></img></p>


         <div className="p-10  bg-white mb-10  mx-auto w-full rounded-md md:flex justify-between">
           <div className="md:mr-5 md:mb-0 mb-5">
              <h4 className="text-[#1F55A6] text-xl mb-5 font-semibold">マイページにログイン</h4>
              <p>新規登録ページで登録したパスワードを使用してマイページにログインする。</p>
           </div>
            <p className="max-w-[332px]"><img src={how_img03} alt=""></img></p>
         </div>
         <p className="flex justify-center mb-10"><img src={yajieushi_icon} alt=""></img></p>

         <div className="p-10  bg-white mb-10  mx-auto w-full rounded-md md:flex justify-between">
           <div className="md:mr-5 md:mb-0 mb-5">
              <h4 className="text-[#1F55A6] text-xl mb-5 font-semibold">チャレンジ資格の購入</h4>
              <p>マイページのチャレンジ購入画面にてチャレンジコースを選択し購入</p>
            </div>
            <p className="max-w-[332px]"><img src={how_img08} alt=""></img></p>
         </div>
            <p className="flex justify-center mb-10"><img src={yajieushi_icon} alt=""></img></p>

         <div className="p-10  bg-white mb-10  mx-auto w-full rounded-md md:flex justify-between">
           <div className="md:mr-5 md:mb-0 mb-5">
            <h4 className="text-[#1F55A6] text-xl mb-5 font-semibold">MT５をダウンロードする</h4>
            <p>ダウンロードページにある、取引環境に合わせたMT5をダウンロードする。</p>
            </div>
            <p className="max-w-[332px]"><img src={how_img04} alt=""></img></p>
         </div>
            <p className="flex justify-center mb-10"><img src={yajieushi_icon} alt=""></img></p>
         <div className="p-10  bg-white mb-10  mx-auto w-full rounded-md md:flex justify-between">
            <div className="md:mr-5 md:mb-0 mb-5">
              <h4 className="text-[#1F55A6] text-xl mb-5 font-semibold">チャレンジスタート</h4>
              <p>2STEPチャレンジ期間の規定を守り目標をクリアすることで、トレーダーとして取引計画を継続できるかを検証します。</p>
            </div>
            <p className="max-w-[332px]"><img src={how_img05} alt=""></img></p>
         </div>
            <p className="flex justify-center mb-10"><img src={yajieushi_icon} alt=""></img></p>

         <div className="p-10  bg-white mb-10  mx-auto w-full rounded-md md:flex justify-between">
           <div className="md:mr-5 -mt-[10px] md:mb-0 mb-5">
              <h4 className="text-[#EFAA00] md:text-3xl text-2xl mb-5 font-bold how_icon">チャレンジクリア</h4>
              <p className="-mt-[20px]">認定トレーダーに昇格後は、取引で得た利益の80%を受け取ることができ、チャレンジ期間にあった一部の条件からも解放されます。</p>
            </div>
            <p className="max-w-[332px]"><img src={how_img06} alt=""></img></p>
          </div>
               <p className="flex justify-center mb-10"><img src={yajieushi_icon} alt=""></img></p>

         <div className="p-10  bg-white mb-10  mx-auto w-full rounded-md ">
         <h4 className="text-[#1F55A6] text-xl mb-5 font-semibold">本人確認手続きをして口座開設と業務提携の契約手続きを行う</h4>
           <div className="md:flex justify-between">
              <p className="md:mr-5 md:mb-0 mb-5">トレーダーは資金を運用するトレード口座を開設する必要があり、<br></br>
              この際に本人確認手続きを行う必要があります。<br></br><br></br>
              本人確認手続きには免許証やマイナンバーカード、パスポートなどの顔写真入り身分証明書が必要です。<br></br><br></br>
              また、トレーダーとして活動するためにJust tradeとトレーダーの間で業務提携の契約を交わすことになります。<br></br>
              本人確認手続きや口座開設、契約手続きは全てオンライン上で行えるシンプル設計になっています。
              </p>
           
            <p className="max-w-[332px]"><img src={how_img03} alt=""></img></p>
            </div>
          </div>
          <p className="flex justify-center mb-10"><img src={yajieushi_icon} alt=""></img></p>

         <div className="p-10  bg-white mb-10  mx-auto w-full rounded-md md:flex justify-between">
           <div className="md:mr-5  md:mb-0 mb-5">
              <h4 className="text-[#1F55A6] text-xl mb-5 font-semibold">Just trade認定トレーダーとしてトレード開始</h4>
              <p>無事全ての諸手続きが完了できましたら、認定トレーダーとしてJust tradeの資金を運用し取引を開始することができます。</p>
           </div>
            <p className="max-w-[332px]"><img src={how_img09} alt=""></img></p>
         </div>
        </div>

        <div className="hidden px-5 xl:flex justify-center  max-w-5xl mx-auto mb-5 pt-10">
          <a href={entryUrl} className="duration-300 rounded-full block xl:mx-0 mx-auto  max-w-xs text-center text-base text-white font-black py-5 px-16 my-2 bg-[#EFAA00] hover:tracking-widest ">新規登録はこちら</a> 
        </div>





       </div>

      {/* 特徴 */}
       <div className="max-w-xl mx-auto md:max-w-5xl px-5 relative mt-[50px] ">
          <a id="demo" className="jump_point"></a>
          <h3 className="md:text-3xl text-2xl font-black mb-10 text-left  text-[#1F55A6] pt-10">デモトレードを始める</h3>
         <div className="p-10  bg-white mb-10  mx-auto w-full rounded-md border-[1px] border-[#1F55A6] md:flex justify-between">
            <div className="md:mr-5 ">
              <h4 className="text-[#1F55A6] text-xl mb-5 font-semibold">マイページにログイン</h4>
              <p><a href={entryUrl} className="text-[#1F55A6] underline " >新規登録ページ</a>に記載したパスワードを使用してマイページにログインする</p>
            </div>
            <p className="max-w-[332px]"><img src={how_img03} alt=""></img></p>
         </div>
         <p className="flex justify-center mb-10"><img src={yajirushi_icon02} alt=""></img></p>
         <div className="p-10  bg-white mb-10  mx-auto w-full rounded-md border-[1px] border-[#1F55A6] md:flex justify-between ">
            <div className="md:mr-5 md:mb-0 mb-5">
              <h4 className="text-[#1F55A6] text-xl mb-5 font-semibold">デモトレードを申請する</h4>
              <p>マイページでのチャレンジ購入画面にてデモトレードを申請する</p>
            </div>
            <p className="max-w-[332px]"><img src={how_img07} alt=""></img></p>
         </div>
            <p className="flex justify-center mb-10"><img src={yajieushi_icon} alt=""></img></p>
         <div className="p-10  bg-white mb-10  mx-auto w-full rounded-md border-[1px] border-[#1F55A6] md:flex justify-between ">
           <div className="md:mr-5 md:mb-0 mb-5">
              <h4 className="text-[#1F55A6] text-xl mb-5 font-semibold">MT５をダウンロードする</h4>
              <p>ダウンロードページにある、取引環境に合わせたMT5をダウンロードする</p>
            </div>
            <p className="max-w-[332px]"><img src={how_img04} alt=""></img></p>
         </div>
           <p className="flex justify-center mb-10"><img src={yajirushi_icon02} alt=""></img></p>
         <div className="p-10  bg-white mb-[100px]  mx-auto w-full rounded-md border-[1px] border-[#1F55A6] md:flex justify-between ">
           <div className="md:mr-5 md:mb-0 mb-5">
              <h4 className="text-[#1F55A6] text-xl mb-5 font-semibold">デモトレードスタート</h4>
              <p>デモトレードでは、チャレンジと同様のチャレンジ期間があり、MT5の操作感や機能の確認、自分にあった様々な取引スタイルを試せます。</p>
            </div>
            <p className="max-w-[332px]"><img src={how_img09} alt=""></img></p>
         </div>

       </div>

        {/* 固定フッター */}
        {fixedItem}
      </Layout>
  </>
  )
}
export default PropHowto

// モーダル用funciton
export function ModalItem( item ) {

  // (※変更不要)モーダル用設定
  const[isModalOpen,setIsModalOpen]=useState(false)
  const closeModal= useCallback(() =>{
    setIsModalOpen(false)
    document.removeEventListener('click',closeModal)
  },[])
  useEffect(()=>{
    return ()=>{
      document.removeEventListener('click',closeModal)
    }
  },[closeModal])
  function openModal(event){
    setIsModalOpen(true)
    document.addEventListener('click',closeModal)
    event.stopPropagation()
  }

  // 子コンポーネント（モーダル）
  function Modal(props){
    const startYOffset = React.useRef(UseScrollPosition())
    startYOffset.current = window.pageYOffset // スクロール位置の左上のY軸座標
    // 調整　モーダル中身（テキスト以外）の設定
    return(
      <div id="modal" className="modal !z-20 text-black w-full text-right" onClick={(event)=>{event.stopPropagation()}}>
        {/* ここでモーダル表示位置を調整 */}
        <div className="absolute left-1/2 w-full mt-[32%] max-w-[320px] -ml-[160px] touch-none" style={{top: startYOffset.current}}> 
          {/* 出力データ */}
          <div className="!text-base absolute w-full bg-white z-20 max-w-[326px]">{item}</div>
          {/* この中のエリア押すと閉じる、閉じるボタン用*/}
          <button onClick={props.onClick} className="w-[30px] h-[30px] bg-black top-0 z-20 relative">
            <div className="!text-base text-white">×</div>
          </button>
        </div>
        {/*この中のエリア押すと閉じる、透過背景用 */}
        <div className="">
          <button onClick={props.onClick} className="w-full bg-gray-700 absolute top-[0px] left-5 h-[8030px] z-10 opacity-50 touch-none"></button>
        </div>
      </div>
    )
  }
  // 親コンポーネント（ボタン）
  return (
    <>
      <div>
        <button onClick={(event)=>{openModal(event)}} className=""> 
          <span className={"" + (isModalOpen? " hidden" :"")}>
            <img alt="" src={hatena_icon}/> {/* 調整　？マークアイコンに差し替える */}
          </span>
          {isModalOpen? <Modal onClick={(event)=>{closeModal(event)}} /> :""}
        </button>
      </div>
    </>
  );
}

// (※変更不要)モーダルスクロール位置取得用function
function UseScrollPosition() {
  const isProcessing = useRef(false)
  const [positionY, setPositionY] = useState(0)
  useEffect(() => {
    const handler = () => {
      if (isProcessing.current) return
      isProcessing.current = true
      window.requestAnimationFrame(() => { // Window.requestAnimationFrame()でpositionYステートの更新を間引く
        isProcessing.current = false
        setPositionY(window.scrollY)
      })
    }
    document.addEventListener('scroll', handler, { passive: true }) // スクロールイベントの登録
    return () => { // スクロールイベントの解除
      document.removeEventListener('scroll', handler)
    }
  }, [])
  return positionY // 取得したスクロール量をreturn
}
